import { createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';

import type { AllFields, WizardContextType } from './types';

export const WizardContext = createContext<WizardContextType>({
    id: '',
    isOpportunity: false,
    steps: [],
    isApplication: false,
    completedSteps: 0,
    form: {} as UseFormReturn<AllFields>,
    getCurrentStep: () => undefined,
    loading: false,
    onComplete: async () => undefined,
    onAutoSave: async () => undefined,
    onOwnerAdd: async () => { throw new Error('onOwnerAdd not implemented'); },
    onOwnerRemoval: async () => undefined,
    onFileRemoval: async () => undefined,
    onFileUpload: async () => { throw new Error('onFileUpload not implemented'); },
    setLoading: () => undefined,
    setShowBackToReview: () => undefined,
    pdfComponent: () => { throw new Error('pdfComponent not implemented.') }
});

const useWizardContext = () => {
    const context = useContext(WizardContext);

    if (!context) {
        throw new Error(`useWizardContext hook can be used only inside Wizard component`);
    }

    return context;
};

export default useWizardContext;
