import styled from 'styled-components';

export const TextComponent = styled.div<{
    fontSize?: string;
    fontWeight?: string;
    color?: string;
}>`
    font-size: ${(props) => props.theme.fontSize?.[props?.fontSize || 'sm']};
    font-weight: ${(props) => props?.fontWeight || '400'};
    color: ${(props) => props.theme?.[props?.color || 'primary']};
`;

export default TextComponent;
